import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { AccessCodeDialog } from '../../../Common/Components/AccessCodeDialog/AccessCodeDialog'
import LoginForm from '../../../Components/Authentication/LoginForm/LoginForm'
import { AuthPageFooter } from '../../../Components/Authentication/AuthPageFooter/AuthPageFooter'
import { updateCurrentUser } from '../../../Reducers/authSlice'
import {
  authService,
  eventService,
  FrontendTypes,
  localStorageService,
  OpenHours,
  ProviderResponse,
  PreferenceType,
  contentService,
} from '../../../Services/services-index'
import { useAppDispatch, useAppSelector } from '../../../Reducers/hooks'
import { imageFileReader } from '../../UserAvatar/UserAvatarSlice'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#1E1E1E',
      '& div[class*="MuiGrid-spacing"]': {
        margin: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          flexWrap: 'nowrap',
        },
      },
      '& div[class*="MuiGrid-item"]': {
        padding: '0 24px',
      },
    },
    item: {
      width: '100%',
    },
  })
)

function useQuery() {
  return new URLSearchParams(decodeURI(useLocation().search))
}

function getQueryValue(query: URLSearchParams, param: string) {
  const queryValue = query.get(param)
  let value: string
  if (!queryValue) {
    value = ''
  } else {
    value = queryValue
  }
  return value
}

const LoginContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const location: any = useLocation()
  const query = useQuery()
  const dispatch = useAppDispatch()

  const { from, hasAuth } = location.state || { from: { pathname: '/' }, hasAuth: false }
  const currentUserStatus = useAppSelector((state) => state.auth.currentUserStatus)
  const currentUser = useAppSelector((state) => state.auth.currentUser)

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [eventCode, setEventCode] = useState<string>('')
  const [accessCode, setAccessCode] = useState<string>('')
  const [errorResponse, setErrorResponse] = useState<string>('')
  const [providers, setProviders] = useState<ProviderResponse>()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [invalidAccessCodeEntered, setInvalidAccessCodeEntered] = useState<boolean>(false)

  const [hasEventBaseDeskEmailId, setHasEventBaseDeskEmailId] = useState<boolean>(false)
  const [eventBaseHelpDeskEmailId, setEventBaseHelpDeskEmailId] = useState<string>('')
  const [openingHoursCheckCalled, setOpeningHoursCheckCalled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const { mutate, data, error, isError, isSuccess, reset } = useMutation((params: any) =>
    authService.signIn(params)
  )

  const eventCodeFromQuery = getQueryValue(query, 'event')
  const accessCodeFromQuery = getQueryValue(query, 'access_code')

  // To collect opening hours information
  useEffect(() => {
    if (!openingHoursCheckCalled && eventCodeFromQuery && currentUser === undefined) {
      eventService
        .getPreference({
          type: PreferenceType.SPECIFIC,
          name: 'login',
          event_code: eventCodeFromQuery,
        })
        .then((res: any) => {
          const currentTime = new Date().getTime()
          const eventOpenHours: OpenHours[] = res?.data[0]['event_open_hours']
          setOpeningHoursCheckCalled(true)
          const isWithinOpenHours = eventOpenHours?.some((hours) => {
            if (hours.from_timestamp && hours.to_timestamp) {
              return (
                new Date(hours.from_timestamp).getTime() < currentTime &&
                new Date(hours.to_timestamp).getTime() > currentTime
              )
            }
            return false
          })

          if (eventOpenHours && !isWithinOpenHours) {
            const sanitizedEventCode = encodeURIComponent(eventCodeFromQuery)
            history.push(`/info?event=${sanitizedEventCode}`)
          }
        })
    }
  }, [])

  // To collect Custom Help-Desk EmailId
  useEffect(() => {
    if (eventCodeFromQuery && !eventBaseHelpDeskEmailId && currentUser === undefined) {
      eventService
        .getPreference({
          type: PreferenceType.SPECIFIC,
          name: 'help_desk_email',
          event_code: eventCodeFromQuery.toString(),
        })
        .then((res: any) => {
          const response = res?.data?.[0]
          if (response && response?.value?.length) {
            setHasEventBaseDeskEmailId(true)
            setEventBaseHelpDeskEmailId(response?.value)
          }
        })
        .catch((err: any) => {
          setErrorResponse(err?.response?.data?.message)
        })
    }
  }, [])

  // To collect provider preferences values
  useEffect(() => {
    const pathName = query.get('event')

    if (currentUser === undefined && !providers) {
      const fetchAll = async () =>
        await authService
          .getProviders(FrontendTypes.VISITOR, pathName)
          .then((response: any) => {
            if (response) {
              const orderPromises = response.data.providers.map((provider: any) =>
                contentService
                  .getFile({
                    filetype: 'sso_button',
                    event_code: pathName,
                    filename: provider.button_filename,
                  })
                  .then((response: any) => {
                    imageFileReader(response?.data).then((res) => (provider.button_image = res))
                  })
              )
              Promise.all(orderPromises).then(() => {
                setProviders(response.data)
                setLoading(false)
              })
            }
          })
          .catch((err: any) => {
            try {
              const errorMessage = err.message || 'An unknown error occurred'
              console.error(`Error: ${errorMessage}`)
            } catch (parseError) {
              console.error('An unexpected error occurred.')
            }
          })
      fetchAll()
    }
  }, [])

  useEffect(() => {}, [providers, openingHoursCheckCalled, eventBaseHelpDeskEmailId])

  const handleLoginSuccess = (userData: any) => {
    dispatch(updateCurrentUser(userData))

    // Store values in localstore
    localStorageService.setLocalStorageItemValue('currentUser', JSON.stringify(userData))
    localStorageService.setLocalStorageItemValue('enableRoomChatFeed', JSON.stringify(true))
    localStorageService.setLocalStorageItemValue('transitionContent', JSON.stringify(true))
    localStorageService.setLocalStorageItemValue('activeUserStatus', 'available')
    localStorageService.setLocalStorageItemValue('disabledPopups', JSON.stringify([]))

    const traverseRedirect = localStorageService.getLocalStorageItemValue('traverseRedirect')

    if (traverseRedirect && hasAuth) {
      localStorageService.clearLocalStorageItemValue('traverseRedirect')
      history.replace(from)
      history.push('/auth')
    } else {
      history.replace(from)
      history.push('/lobby')
    }
  }

  useEffect(() => {
    if (isError || !errorResponse) {
      setErrorResponse((error as any)?.response?.data?.message)
    }
  }, [isError, errorResponse, currentUserStatus])

  useEffect(() => {
    if (isSuccess) {
      const { user_data } = (data as any).data
      handleLoginSuccess(user_data)
    }
  }, [isSuccess])

  // For future - access code currently not supported by user_pass_login
  useEffect(() => {
    const errorText = (error as any)?.response?.data?.message

    // Missing / invalid access code response
    if (isError && errorText.toLowerCase().includes('access code')) {
      setOpenDialog(true)
      if (accessCode) {
        setInvalidAccessCodeEntered(true)
      }
      // Other responses, e.g. invalid event code
      else {
        setInvalidAccessCodeEntered(false)
        setOpenDialog(false)
      }
    }
  }, [isError, errorResponse, currentUserStatus])

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleConfirmDialog = (_accessCode: string) => {
    mutate({
      email: username.trim(),
      password: password?.trim(),
      event_code: eventCode?.trim(),
      access_code: _accessCode.trim(),
      frontend: FrontendTypes.VISITOR,
      force_login: true,
    })
    setAccessCode(_accessCode)
    setOpenDialog(false)
  }

  const onSubmit = (username: string, password: string, eventCode?: string) => {
    reset()
    setUsername(username)
    setPassword(password)
    setEventCode(eventCode ? eventCode : eventCodeFromQuery ? eventCodeFromQuery : '')
    const _eventCode = eventCode ? eventCode : eventCodeFromQuery ? eventCodeFromQuery : ''

    // For future - access code currently not supported by user_pass_login
    if (accessCodeFromQuery) {
      mutate({
        email: username.trim(),
        password: password?.trim(),
        event_code: _eventCode.trim(),
        access_code: accessCodeFromQuery.trim(),
        frontend: FrontendTypes.VISITOR,
        force_login: true,
      })
    } else {
      const traverseRedirect = localStorageService.getLocalStorageItemValue('traverseRedirect')
      if (traverseRedirect) {
        mutate({
          email: username.trim(),
          password: password?.trim(),
          event_code: _eventCode.trim(),
          frontend: FrontendTypes.TRAVERSE,
          force_login: true,
        })
      } else {
        mutate({
          email: username.trim(),
          password: password?.trim(),
          event_code: _eventCode.trim(),
          frontend: FrontendTypes.VISITOR,
          force_login: true,
        })
      }
    }
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center' alignItems='center' direction='column' spacing={6}>
        <Grid item className={classes.item}>
          {!loading && (
            <LoginForm
              providers={providers}
              eventCodeFromQuery={eventCode ? eventCode : eventCodeFromQuery}
              accessCodeFromQuery={accessCodeFromQuery}
              loginFailMsg={errorResponse}
              onSubmit={onSubmit}
            />
          )}
          <AccessCodeDialog
            titleText={t('authPages.accessCodeDialog.header')}
            contentText={t('authPages.accessCodeDialog.content')}
            placeholder={t('authPages.accessCodeDialog.placeholder')}
            cancelText={t('authPages.accessCodeDialog.cancel')}
            confirmText={t('authPages.accessCodeDialog.confirm')}
            open={openDialog}
            invalidCodeEntered={invalidAccessCodeEntered}
            errorMessage={t('authPages.accessCodeDialog.errorMessage')}
            onConfirm={handleConfirmDialog}
            onCancel={handleCloseDialog}
          />
        </Grid>
        <Grid item className={classes.item}>
          <AuthPageFooter
            showRegisterPageInfo={false}
            showForgotPasswordInfo={false}
            hasEventBaseDeskEmailId={hasEventBaseDeskEmailId}
            eventBaseHelpDeskEmailId={eventBaseHelpDeskEmailId}
          ></AuthPageFooter>
        </Grid>
      </Grid>
    </div>
  )
}

export default LoginContainer
