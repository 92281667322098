import React, { FC, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { localStorageService } from '../../../Services/services-index'
import { authService } from '../../../Services/services-index'
import { updateCurrentUser } from '../../../Reducers/authSlice'
import { useAppDispatch } from '../../../Reducers/hooks'
import './after-login-page.css'

function useSearchParams() {
  return new URLSearchParams(decodeURI(useLocation().search))
}

export const AfterLoginPage: FC = () => {
  const searchParams = useSearchParams()
  const history = useHistory()
  const location: any = useLocation()
  const dispatch = useAppDispatch()

  const [accessCode, setAccessCode] = useState('')
  const [accessCodeErrorMessage, setAccessCodeErrorMessage] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [invalidAccessCodeEntered, setInvalidAccessCodeEntered] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [retryToken, setRetryToken] = useState('')

  const alreadyLoaded = useRef(false)

  const { from } = location.state || {
    from: { pathname: '/' },
  }

  useEffect(() => {
    const launchRedirection = async () => {
      const callbackCode = searchParams.get('code') ?? ''
      const eventCode = sessionStorage.getItem('eventCode')
      const provider = sessionStorage.getItem('provider')
      const redirectTo: string | null = sessionStorage.getItem('redirect_to')

      if (!provider || !eventCode) {
        console.error('No provider or eventCode', provider, eventCode)
        return
      }

      await authService
        .postOauth2('visitor', provider, eventCode, accessCode, retryToken, callbackCode)
        .then((res) => {
          console.log('[LOGIN SUCCESSFULL]', res)

          if (res.data && res.data.user_data && res.data.user_data.id) {
            if (redirectTo !== null && redirectTo !== '') {
              const redirectToUrl = new URL(redirectTo)
              redirectToUrl.searchParams.append('jwtToken', res.data.user_data.jwt_token)
              window.location.href = redirectToUrl.toString()
              return
            }

            dispatch(updateCurrentUser(res.data.user_data))

            localStorageService.setLocalStorageItemValue(
              'currentUser',
              JSON.stringify(res.data.user_data)
            )
            console.log(
              'current user get after login',
              localStorageService.getLocalStorageItemValue('currentUser')
            )
            localStorageService.setLocalStorageItemValue('enableRoomChatFeed', JSON.stringify(true))
            localStorageService.setLocalStorageItemValue('transitionContent', JSON.stringify(true))
            localStorageService.setLocalStorageItemValue('activeUserStatus', 'available')
            localStorageService.setLocalStorageItemValue('disabledPopups', JSON.stringify([]))
            localStorageService.clearLocalStorageItemValue('eventCode')

            history.replace(from)
            history.push('/lobby')
          }
        })
        .catch((err) => {
          console.log('[ERROR DURING LOGIN]')
          const errorCode = err?.response?.data?.error_code
          const errorText = err?.response?.data?.message
          const _retryToken = err?.response?.data?.retry_token
          setRetryToken(_retryToken ? _retryToken : '')

          // Missing / invalid access code response
          if (_retryToken && errorText.toLowerCase().includes('access code')) {
            setAccessCodeErrorMessage(errorText)
            setOpenDialog(true)
            if (accessCode) {
              setInvalidAccessCodeEntered(true)
            }
          } else {
            // Other responses, e.g. invalid event code
            setErrorMessage(errorText)
            setInvalidAccessCodeEntered(false)
            setOpenDialog(false)
          }

          // User takes too long to enter access code
          if (errorText === 'Login session expired.') {
            setTimeout(() => {
              history.push('/auth/login')
            }, 4000)
          }

          if (errorCode === 401.2) {
            setTimeout(() => {
              history.push('/auth/login')
            }, 4000)
          }
        })
    }

    if (alreadyLoaded.current === false) {
      alreadyLoaded.current = true
      launchRedirection()
    } else {
      console.error('Preventing duplicate call')
    }
  }, [])

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        flexDirection: 'column',
        gap: '2rem',
      }}
    >
      {!errorMessage && !openDialog ? (
        <>
          <div className='loader' />
          <p style={{ color: '#666', fontSize: '1.2rem' }}>Please wait while we log you in...</p>
        </>
      ) : (
        <div
          style={{
            background: 'white',
            padding: '2rem',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            maxWidth: '400px',
            width: '90%',
            textAlign: 'center',
          }}
        >
          {openDialog && (
            <div style={{ marginBottom: '1.5rem' }}>
              <h3 style={{ color: '#e74c3c', marginBottom: '1rem' }}>Access Code Required</h3>
              <p style={{ color: '#666', marginBottom: '1rem' }}>{accessCodeErrorMessage}</p>
              <input
                type='text'
                value={accessCode}
                onChange={(e) => setAccessCode(e.target.value)}
                style={{
                  width: '100%',
                  padding: '0.75rem',
                  border: `2px solid ${invalidAccessCodeEntered ? '#e74c3c' : '#ddd'}`,
                  borderRadius: '4px',
                  fontSize: '1rem',
                  marginBottom: '1rem',
                }}
                placeholder='Enter access code'
              />
              <button
                onClick={() => setOpenDialog(false)}
                style={{
                  background: '#3498db',
                  color: 'white',
                  border: 'none',
                  padding: '0.75rem 1.5rem',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  transition: 'background 0.2s',
                }}
                onMouseOver={(e) => (e.currentTarget.style.background = '#2980b9')}
                onMouseOut={(e) => (e.currentTarget.style.background = '#3498db')}
              >
                Submit
              </button>
            </div>
          )}

          {errorMessage && (
            <div>
              <h3 style={{ color: '#e74c3c', marginBottom: '1rem' }}>Error</h3>
              <p style={{ color: '#666' }}>{errorMessage}</p>
              {errorMessage === 'Login session expired.' && (
                <p style={{ color: '#666', marginTop: '1rem' }}>
                  Redirecting to login page in a few seconds...
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
